<template>
    <modal ref="modalSubirEntregaCalificada" titulo="Subir entrega calificada">
        <div class="container-fluid">
            <div class="row justify-content-lg-center my-3">
                <div class="col-lg-9">
                    <p class="f-12 text-center">Título de la entrega</p>
                    <el-input v-model="presentationTitle" placeholder="Título: Taller, Examen, Ensayo" class="w-100 f-12 text-center" />
                </div>
            </div>
            <div class="row justify-content-lg-center my-3">
                <div class="col-lg-9">
                    <p class="f-12 text-center">Calificación</p>
                    <el-input v-model="presentationGrade" placeholder="Calificacion: 0, 1, 2..." class="w-100 f-12" />
                </div>
            </div>
            <div class="row justify-content-lg-center my-3">
                <div class="col-lg-9">
                    <p class="f-12 text-center">Fecha de entrega</p>
                </div>
                <div class="col-lg-9">
                    <div class="d-flex justify-content-lg-center">
                        <el-date-picker
                        v-model="uploadDate"
                        type="date"
                        placeholder="Pick a day"
                        class="w-75 f-12"
                        />
                    </div>
                </div>
            </div>
            <div class="row justify-content-lg-center my-3">
                <div class="col-lg-9">
                    <p class="f-12 text-center">Hora de entrega</p>
                </div>
                <div class="col-lg-9">
                    <div class="d-flex justify-content-lg-center">
                        <el-time-select
                        v-model="uploadHour"
                        :picker-options="{
                            start: '00:00',
                            step: '00:01',
                            end: '23:59'
                        }" placeholder="Hora de entrega" 
                        class="w-75 f-12"
                        />
                    </div>
                </div>
            </div>
            <div class="row justify-content-lg-center my-4">
                <div class="col-lg-9 text-center">
                    <el-upload
                    class="upload-demo container"
                    action="https://jsonplaceholder.typicode.com/posts/"
                    >
                        <div class="d-flex justify-content-lg-between">
                            <p class="f-12 my-auto">Añadir adjunto</p>
                            <i class="icon-plus f-25 cr-pointer" />
                        </div>   
                    </el-upload>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            uploadHour: '',
            uploadDate: '',
            presentationTitle: '',
            presentationGrade: '',
        }
    }, 
    watch :{ 
        presentationGrade(val){
            this.presentationGrade = this.presentationGrade.replace(/\D/g, '')
        }
    },
    methods: {
        toggle(){
            this.$refs.modalSubirEntregaCalificada.toggle()
        }
    }
}
</script>

<style>

</style>